.background {
    background: rgba(var(--dark), 0.9);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 500;
}

.textWhite {
    color: white;
}

.container {
    margin: auto;
    max-width: 1200px;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.headerImg {
    height: 4rem;
    width: 4rem;
    padding: .5rem
}

.headerDesc {
    padding-left: 10px;
    font-size: 2rem;
    color: rgb(var(--accent));
    font-family: 'Kalam', cursive;
    font-weight: bold;
}

.textWhite {
    color: white;
    font-family: inherit;
}

.list {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    list-style-type: none;
    position: absolute;
    top: 4rem;
    right: -50%;
    background: rgba(var(--dark), 0.9);

    height: calc(100vh - 4rem);
    width: 50%;
    transition: transform .6s ease;
}

.listItem {
    margin: 1rem 0;
}

.listOpen {
    transform: translateX(-100%);
}

.listItemAnchor {
    padding: 1rem;
    text-decoration: none;
    color: rgb(255, 255, 255);
    opacity: 0.8;
    font-size: 1rem;
}

.listItemAnchor:hover {
    opacity: 1;
}

.activeLink {
    color: rgb(var(--accent));
    opacity: 1;
}

.navBackgroundMobile {
    position: absolute;
    top: 4rem;
    left: 0;
    width: 50%;
    height: calc(100vh - 4rem);
    z-index: 10;
}

/* burger */

.burgerMenu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.burgerBtn {
    width: 30px;
    height: 3px;
    background: white;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}

.burgerBtn::before,
.burgerBtn::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: white;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}

.burgerBtn::before {
    transform: translateY(-8px);
}

.burgerBtn::after {
    transform: translateY(8px);
}

.burgerOpen .burgerBtn {
    transform: translateX(-50px);
    background: transparent;
}

.burgerOpen .burgerBtn::before {
    transform: rotate(45deg) translate(35px, -35px);
}

.burgerOpen .burgerBtn:after {
    transform: rotate(-45deg) translate(35px, 35px);
}

/* burger end */



/* media query */

@media (min-width: 1000px) {

    .burgerMenu {
        display: none;
    }

    .list {
        background: initial;
        position: initial;
        width: initial;
        flex-direction: row;
        height: 4rem;
    }
}