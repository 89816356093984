.background {
    background: white;
    color: black;
}

.desc {
    flex: 1;
    font-size: 1rem;
    line-height: 1.5rem;
    align-self: flex-start;
    font-style: italic;

}

.desc::first-letter {
    font-size: 200%;
    color: rgb(var(--accent));
    font-weight: bold;
}

.svg {
    max-width: 100%;
    max-height: 100%;
    flex: 1;
    padding: 0 1rem;
    order: -1;
}

.svg svg {
    height: 100%;
    width: 100%;
}


/* setion PAGES */

.pages {
    margin: 4rem auto 0 auto;
    max-width: 1200px;
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
}

.pages__container {
    padding: 0;
}

.pages__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
}

/* setion APPS */

.apps {
    margin: 4rem auto 0 auto;
    max-width: 1200px;
    padding: 0 1rem 4rem 1rem;
    display: flex;
    flex-direction: column;
}

.apps__container {
    padding: 0;
}

.apps__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
}

/* svg animations */

.animationUPDown {
    animation: animationUPDown 4s ease-in-out infinite;
}

@keyframes animationUPDown {
    50% {
        transform: translateY(8%);
    }
}

.animationShowHide {
    animation: animationShowHide 3s ease-in-out infinite;
}

@keyframes animationShowHide {
    50% {
        opacity: 0.1;
    }
}

.animationBackgroundColor {
    animation: animationBackgroundColor 4s ease-in-out infinite;
}

@keyframes animationBackgroundColor {
    0% {
        fill: rgba(255, 255, 255, 1);
    }

    50% {
        fill: rgb(0, 200, 0, 1);
    }

    100% {
        fill: rgba(255, 255, 255, 1);
    }
}

.animationSkew {
    animation: animationSkew 4s ease-in-out infinite;
}

@keyframes animationSkew {
    50% {
        transform: scale(0.9, 0.9) skew(5deg, 5deg);
        opacity: 0.2;

    }
}

.animationBackgroundColorCheck1 {
    animation: animationBackgroundColorCheck 3s ease-in-out infinite both;
}

.animationBackgroundColorCheck2 {
    animation: animationBackgroundColorCheck 3s 1s ease-in-out infinite both;
}

.animationBackgroundColorCheck3 {
    animation: animationBackgroundColorCheck 3s 2s ease-in-out infinite both;
}

@keyframes animationBackgroundColorCheck {
    0% {
        fill: transparent;
    }

    50% {
        fill: rgb(0, 200, 0, 1);
    }

    100% {
        fill: transparent;
    }
}

.animationRotate {
    animation: animationRotate 4s ease-in-out infinite;
    transform-origin: 72% 65%;
}

@keyframes animationRotate {
    0% {
        transform: rotateY(0);
        fill: rgb(0, 200, 0, 1);
    }

    50% {
        transform: rotateY(360deg);
        fill: rgb(141, 141, 141);
    }

    100% {
        transform: rotateY(0);
        fill: rgb(0, 200, 0, 1);
    }
}

/* section INFO */

.info {
    background: rgb(var(--accent));
}

.info__container {
    max-width: 1920px;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info__figure1,
.info__figure2 {
    flex: 2;
}

.info__figure1 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
    align-self: flex-end;
}

.info__figure2 {
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 100%);
    align-self: flex-start;
}

.info__img {
    width: 100%;
    height: 100%;
    display: block;
}

.info__textContainer {
    flex: 1;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
}

.info__text1 {
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    align-self: center;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);

}

.info__text2 {
    color: black;
    font-size: 1.5rem;
    letter-spacing: 1.2px;
    font-family: "Kalam", cursive;
    font-weight: 600;
    align-self: center;
    margin: 1rem 0 0 4rem;
    text-transform: uppercase;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5);

}



/* media query */

@media (min-width: 500px) {}


@media (min-width:800px) {

    .svg {
        order: 0;
    }

    .desc {
        font-size: 1.4rem;
        line-height: 2rem;
        align-self: center;
    }

    .pages__item {
        flex-direction: row;
    }

    .apps__item {
        flex-direction: row;
    }

    .info__container {
        flex-direction: row;
    }

    .info__textContainer {
        margin: 0 -1rem;
    }

    .info__figure1 {
        clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
    }

    .info__figure2 {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
    }
}