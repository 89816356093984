.background {
    background: white;
    color: black;
}

.text__desc {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 1rem;
    text-align: justify;
    color: black;
}

.text__white {
    color: white;
}

.text__bigLeter {
    font-size: 200%;
    line-height: 2rem;
}

.figure__img {
    flex: 1;
    height: 100%;
    width: 100%;
}

.img {
    height: 100%;
    width: 100%;
    display: block;
}



/* section WHO */

.who {
    background-color: white;
}

.who__container {
    max-width: 1920px;
    margin: 4rem auto 0 auto;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.who__text {
    flex: 1;
}

.who__textContainer {
    max-width: 800px;
    margin-right: auto;
    padding: 2rem 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
}


/* section WHY */

.why {
    background-color: rgb(var(--dark));

}

.why__container {
    max-width: 1920px;
    margin: auto;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.why__text {
    flex: 1;
}

.why__textContainer {
    max-width: 800px;
    margin-left: auto;
    padding: 2rem 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
}

/* section APPROACH */

.approach {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2)0%, rgba(0, 0, 0, 0.2)100%) center center no-repeat, url("../../assets/background_about.jpg");
    background-size: cover;
    background-attachment: fixed;
}

@supports (-webkit-overflow-scrolling: touch) {
    .approach {
        background-attachment: scroll;
    }
}

.approach__textContainer {
    margin: auto;
    max-width: 1200px;
    width: 100%;
    padding: 4rem 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
}

.approach__item {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6));
}

.approach__h2 {
    font-size: 1.5rem;
    padding: 1rem 0;
}

.approach__p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.5rem;
}


/* section STANDARDS */

.standards {
    background: white;
}

.standards__container {
    margin: auto;
    max-width: 1200px;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
}

.standards__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -4rem;
    padding-top: 2rem;
}

.standards__content {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 1rem 0 1rem;
    padding: 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transform: translateY(2rem);
    z-index: 1;
}

.standards__header {
    font-size: 2rem;
    color: rgb(var(--accent));
    padding-bottom: 1rem;
}

.standards__header2 {
    font-size: 1.5rem;
    padding-bottom: 1rem;
}

.standards__text {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 1rem;
    text-align: justify;
}

.standards__figure {
    width: 100%;
    height: 100%;
    flex: 1.5;
    transform: translateX(0);
}


/* MEDIA */

@media (min-width: 600px) {

    .approach__textContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 800px) {
    .who__container {
        flex-direction: row;
    }

    .why__container {
        flex-direction: row;
    }
}


@media (min-width: 1000px) {

    .approach__textContainer {
        grid-template-columns: repeat(4, 1fr);
    }

    .standards__item {
        flex-direction: row;
        margin-top: 0;
    }

    .standards__content {
        transform: translateX(2rem);
        margin: 0;
    }

    .standards__figure {
        transform: translateX(-2rem);
    }
}