.background {
    background: white;
    color: black;
}

.font {
    font-family: 'Kalam', cursive;
}

.title {
    font-size: 2.5rem;
    align-self: center;
    color: rgb(var(--accent));
    align-self: center;
}

.img {
    width: 100%;
    height: 100%;
    display: block;
}

.link {
    margin-top: 2rem;
    padding: 0.5rem 2.5rem 0.5rem 2rem;
    color: black;
    text-decoration: none;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    border: 0.2rem solid rgb(var(--accent), 1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 999px;
}

.link:active {
    background: rgba(var(--accent), 0.5);
}

.linkLeftPadding {
    padding-left: 1rem;
}

.textWhite {
    color: white;
    font-family: inherit;
}

.linkArrow {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    content: "";
    display: inline-block;
    padding: 0.3rem;
    border: rgb(var(--accent)) solid;
    border-width: 0.2rem 0.2rem 0 0;
    animation: move 1s ease-in-out infinite;
}

@keyframes move {
    0% {
        transform: rotate(45deg) translate(0, 50%);
    }

    50% {
        transform: rotate(45deg) translate(50%, 0);
    }

    100% {
        transform: rotate(45deg) translate(0, 50%);
    }
}


/* section START */

.start {
    background-color: rgb(var(--dark));
    width: 100%;
    margin-top: 4rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    /*full screen in mobile first section (cut browser search bar on mobile) + js code*/
    height: calc(100vh - 4rem);
    height: calc(var(--vh, 1vh) * 100 - 4rem);
}

.start__container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

.start__containerText {
    padding: 1rem;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.2));
    border-radius: 2rem;
}

.start__titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: center;
}

.start__logo {
    height: 3rem;
    width: 3rem;
}

.start__title {
    font-size: 2.5rem;
    color: rgb(var(--accent));
    font-family: "Kalam", cursive;
    font-weight: 600;
    text-align: center;
    margin-left: 1rem;
}

.start__text {
    font-size: 1.2rem;
    text-align: left;
    padding: 0.5rem;
    color: white;
    white-space: nowrap;
}

.start__text1 {
    animation: showText 1s ease forwards;
    animation-delay: 0.5s;
    opacity: 0;
}

.start__text2 {
    margin-left: 3rem;
    animation: showText 1s ease forwards;
    animation-delay: 1.5s;
    opacity: 0;
}

.start__text3 {
    margin-left: 6rem;
    animation: showText 1s ease forwards;
    animation-delay: 2.5s;
    opacity: 0;
}

.start__videoContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
}

.start__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.start__videoFilter {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
}

.start__arrow {
    position: absolute;
    bottom: 8rem;
    left: 50%;
    width: 1.5rem;
    height: 1.5rem;
    border: solid white;
    border-width: 0.2rem 0.2rem 0 0;
    transform: rotate(135deg) translate(40%);
    animation: moveArrow 1s ease-in-out infinite;
}

@keyframes moveArrow {
    50% {
        transform: rotate(135deg) translateY(40%);
    }
}

@keyframes showText {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


/* section TOOLS */

.tools {

    /*full screen in mobile first section (cut browser search bar on mobile) + js code*/
    margin: 100vh auto 0 auto;
    margin: calc(var(--vh, 1vh) * 100) auto 0 auto;

    max-width: 1200px;
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
}

.tools__container {
    padding-top: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    align-items: center;
    justify-items: center;
}

.tools__figure {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.tools__figure_padding {
    padding: 0.5rem;
}

.tools__figure_textMore {
    padding: 0 0 0 0;
    box-shadow: unset;
}

/* section APPROACH */

.approach {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5)100%), url('../../assets/background_home.jpg') center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

@supports (-webkit-overflow-scrolling: touch) {
    .approach {
        background-attachment: scroll;
    }
}

.approach__content {
    margin: auto;
    max-width: 1200px;
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
}

.approach__container {
    padding-top: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    align-items: center;
    justify-items: center;
}

.approach__item {
    clip-path: polygon(10% 4%, 100% 4%, 90% 96%, 0 96%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.6);
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.4));
}


.approach__figure {
    width: 8rem;
    height: 8rem;
    clip-path: circle(50%);
}

.approach__text {
    font-size: 1.5rem;
    color: black;
    padding-top: 2rem;
    text-align: center;
    font-weight: 400;
}

.approach__textEmphasize {
    font-size: 2rem;
    font-family: "Kalam", cursive;
    font-weight: 600;
    color: black;
}


/* section OFFER */

.offer {
    margin: auto;
    max-width: 1200px;
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
}

.offer__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
}

.offer__content {
    background-color: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.offer__header {
    font-size: 2rem;
    color: rgb(var(--accent));
    padding-bottom: 1rem;
}

.offer__text {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 1rem;
    text-align: justify;
}

.offer__figure {
    width: 100%;
    height: 100%;
    flex: 1.5;
    position: relative;
}

.offer__img {
    height: 4rem;
    width: 4rem;
    position: absolute;
    top: 2rem;
    right: 3rem;
    background-color: white;
    padding: 0.5rem;
    border-radius: 50%;
    animation: scaleImg 1.5s ease-in-out infinite;
}

@keyframes scaleImg {
    50% {
        transform: scale(0.9);
    }
}



/* section PRICE */

.price {
    background: url('../../assets/background_price.jpg') center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 8rem 0;
}

@supports (-webkit-overflow-scrolling: touch) {
    .price {
        background-attachment: scroll;
    }
}

.price__background {
    background: rgba(var(--dark), 1);
}

.price__containerText {
    max-width: 1200px;
    margin: auto;
    padding: 4rem 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.price__content {
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
}

.price__item {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.price__desc {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1.2px;
    color: white;
    padding-left: 0.5rem;
}

/* section REALIZATIONS */

.realizations {
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: auto;
}

.slider {
    width: 100%;
    height: 100%;
    position: relative;
    /* transform: rotate(355deg); */
}

.slider__img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    pointer-events: none;
    top: 23px;
    width: 38%;
}

@media (min-width: 460px) {
    .slider__img {
        top: 20px;
        width: 38%;
    }
}

@media (min-width: 650px) {
    .slider__img {
        top: 14px;
        width: 39%;
    }
}

@media (min-width: 800px) {
    .slider__img {
        top: 10px;
        width: 39%;
    }
}

@media (min-width: 1000px) {
    .slider__img {
        top: 4px;
        width: 39%;
    }
}

@media (min-width: 1200px) {
    .slider__img {
        top: -1px;
        width: 39%;
    }
}


/* section USER */

.user {
    width: 100%;
    background: rgb(var(--dark));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.user__figure {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.user__img {
    width: 100%;
    height: 100%;
    max-width: 600px;
    display: block;
}

.user__container {
    width: 100%;
}

.user__textContainer {
    padding: 1rem;
}


/* section CONTACT */

.contact {
    max-width: 1200px;
    margin: auto;
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
}

.contact__container {
    display: flex;
    flex-direction: column;
}

.contact__figure {
    height: 100%;
    width: 100%;
    flex: 1;
}

.contact__desc {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.contact__tel {
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: rgb(var(--dark));
    font-weight: 300;
    padding-top: 2rem;
}

.contact__telIcon {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: rgb(var(--accent));

    /* animation */
    animation: shake 2s cubic-bezier(.36, .07, .19, .97) infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {

    2%,
    98% {
        transform: translate3d(-1px, 0, 0);
    }

    4%,
    96% {
        transform: translate3d(2px, 0, 0);
    }

    6%,
    94% {
        transform: translate3d(-4px, 0, 0);
    }

    8%,
    92% {
        transform: translate3d(4px, 0, 0);
    }
}



/* media query */


@media (min-width: 600px) {

    .link {
        font-size: 1.5rem;
    }

    .title {
        font-size: 2rem;
    }

    .start__logo {
        height: 6rem;
        width: 6rem;
    }

    .start__title {
        font-size: 5rem;
    }

    .start__text {
        font-size: 3rem;
    }

    .approach__container {
        grid-template-columns: repeat(2, 1fr);
    }

    .offer__img {
        height: 6rem;
        width: 6rem;
    }

    .price__desc {
        font-size: 1.5rem;
    }
}

@media (min-width: 800px) {

    .price__content {
        grid-template-columns: repeat(3, 1fr);
        max-width: initial;
    }

    .price__figure {
        display: block;
    }

    .user {
        flex-direction: row;
    }

    .user__figure {
        margin-top: -8rem;
        width: 50%;
    }

    .user__container {
        width: 50%;
    }

    .user__textContainer {
        max-width: 600px;
        margin-left: auto;
    }

    .tools__container {
        grid-template-columns: repeat(6, 1fr);
    }

    .tools__figure_textMore {
        padding: 50% 0 0 0;
        box-shadow: unset;
    }

    .offer__item {
        flex-direction: row;
        margin-top: 0;
    }

    .offer__content {
        margin: 0;
    }

    .contact__container {
        flex-direction: row;
        align-items: center;
    }
}

@media (min-width: 1000px) {

    .approach__container {
        grid-template-columns: repeat(3, 1fr);
    }

}