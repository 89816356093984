.background {
    color: black;
    position: relative;

}

.img {
    width: 100%;
    height: 100%;
    display: block;
}


/* section PRICE */

.price {
    width: 100%;
    height: 100%;
    position: relative;
    background: rgb(var(--dark));
    z-index: -2;
}

.price__background {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7)100%), url('../../assets/background_price.jpg') center center no-repeat;
    background-size: cover;
    z-index: -1;

    /*full screen in mobile first section (cut browser search bar on mobile) + js code*/
    height: 120vh;
    height: calc(var(--vh, 1vh) * 120);
}

.price__container {
    max-width: 1200px;
    margin: auto;
    padding: 8rem 1rem 4rem 1rem;
    display: flex;
    flex-direction: column;
}

.price__content {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding-top: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
}

.price__item {
    width: 100%;
    height: 100%;
    position: relative;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2));
    border-radius: 2rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}


.price__itemTextMine {
    font-size: 1.5rem;
    color: rgb(var(--accent));
    font-family: 'Kalam', cursive;
    font-weight: 500;
    line-height: 2rem;
    text-align: center;
    text-shadow: 1px 1px rgba(0, 0, 0, 1);

}

.price__itemTextPrice {
    font-size: 2.25rem;
    color: black;
    font-weight: 400;
    text-align: center;
    padding-top: 0.5rem;
}

.price__itemLineText {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
}

.price__itemDesc {
    font-size: 1rem;
    color: black;
    padding-left: 0.5rem;
}


/* media query */


@media (min-width: 800px) {

    .price__content {
        grid-template-columns: repeat(3, 1fr);
        max-width: initial;
    }

}