.background {
    background: rgb(var(--dark));
}

.desc {
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem 4rem 1rem;
}

.desc_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.desc__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    padding-top: 4rem;
}

.desc__title {
    font-size: 1.2rem;
    color: rgb(var(--accent));
    letter-spacing: 2px;
    font-family: 'Kalam', cursive;
}

.desc__text {
    font-size: 1rem;
    color: white;
    padding: 2rem 0 0 0;
    line-height: 2rem;
}

.desc__tools {
    display: flex;
    align-items: center;
    padding-top: 2rem;
}

.desc__img {
    width: 2rem;
    height: 2rem;
    display: block;
    margin: 0 0.5rem;
}

.desc__nav {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.desc__link {
    color: white;
    text-decoration: none;
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.desc__arrow {
    margin-right: 1rem;
    content: "";
    display: inline-block;
    padding: 0.2rem;
    border: rgb(var(--accent)) solid;
    border-width: 0.2rem 0.2rem 0 0;
    transform: rotate(45deg);
}

.desc__phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2rem;
}

.desc__phoneText {
    font-size: 1.6rem;
    color: white;
}

.desc__contact {
    display: flex;
    align-items: center;
    padding-top: 2rem;
}

.desc__contactItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    position: relative;
    text-decoration: none;
}

.desc__contactItemSvg {
    height: 1.5rem;
    width: 1.5rem;
}

.desc__contactItemSvg:active {
    transform: scale(1.5);
}

.desc__contactItemText {
    color: white;
    font-size: 0.5rem;
    transition: 0.3s ease-in-out;
    opacity: 0;
}

.desc__contactItemSvg:hover+.desc__contactItemText {
    transform: scale(2) translateY(150%);
    opacity: 1;
}





.desc__contactSvgBig {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;

    /* animation */
    animation: shake 1.5s cubic-bezier(.36, .07, .19, .97) infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 100px;
}


@keyframes shake {

    2%,
    98% {
        transform: translate3d(-1px, 0, 0);
    }

    4%,
    96% {
        transform: translate3d(2px, 0, 0);
    }

    6%,
    94% {
        transform: translate3d(-4px, 0, 0);
    }

    8%,
    92% {
        transform: translate3d(4px, 0, 0);
    }
}

.foot {
    background: rgba(var(--bright), 0.5);
    text-align: center;
    color: white;
    font-size: 1rem;
    padding-top: 0.5rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.foot_link {
    text-decoration: none;
    color: white;
}


@media (min-width:800px) {

    .desc_container {
        flex-direction: row;
    }
}