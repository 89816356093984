/* basic */

*,
*:before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

:root {
  /* colors */
  --accent: 0, 200, 0;
  /* #00c800 */

  --dark: 30, 30, 30;
  --bright: 230, 230, 230;

}

html,
body {
  font-size: 100%;
  overflow-x: hidden;
  color: black;

  /* scrollbar ONLY for mozilla, scrollbar-width - "auto" or "thin"  */
  scrollbar-width: thin;
  scrollbar-color: green black;
}

/* custom scroll bar in body (mobile not work) */
body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: black;
}

body::-webkit-scrollbar-thumb {
  background: rgba(var(--accent), 0.6);
  border-radius: 999px;
}

/* ALL */

.textContainer {
  border-left: rgb(var(--accent)) 0.2rem solid;
  padding-left: 0.5rem;
}

.marginLeftRight {
  margin: 0 1rem;
}


.text1 {
  letter-spacing: 1.2px;
  font-size: 1.2rem;
  color: rgb(var(--accent));
  font-family: "Kalam", cursive;
  font-weight: 600;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.text2 {
  font-size: 2rem;
  font-weight: 600;
  color: black;
}

.text__white {
  color: white;
}

@media (min-width: 500px) {

  .textContainer {
    padding-left: 1.5rem;
  }


  .text1 {
    font-size: 1.5rem;
  }

  .text2 {
    font-size: 2.5rem;
  }
}