.background {
    background: white;
    color: black;
}


.img {
    width: 100%;
    height: 100%;
    display: block;
}


/* setion REALIZATIONS */

.realizations {
    padding: 8rem 1rem 4rem 1rem;
    margin: 4rem auto 0 auto;
    margin: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}

.realizations__container {
    display: flex;
    flex-direction: column;
}

.realizations__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4rem;
}

.realizations__itemTextContainer {
    background: linear-gradient(to right bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
    flex: 1;
    margin: 0 0 2rem 0;
    padding: 1rem 1rem 2rem 1rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    position: relative;
}

.realizations__text {
    font-size: 1.2rem;
    padding-top: 1rem;
    color: black;
    font-weight: 500;
}

.realizations__link {
    display: inline-block;
    padding-top: 1rem;
}

.realizations__figure {
    flex: 1;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}


/* border ANIMATION */

.animation__border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
    overflow: hidden;
    pointer-events: none;
}

.animation__border:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 0.3rem;
    background: linear-gradient(180deg, rgb(var(--accent)), rgb(50, 0, 0));
    animation: animateBorderVertical1 4s linear infinite;
    animation-delay: -1s;
}

@keyframes animateBorderVertical1 {
    0% {
        transform: translateY(100%);
    }

    50% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(-100%);
    }
}

.animation__border:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.3rem;
    background: linear-gradient(90deg, rgb(0, 0, 500), rgb(var(--accent)));
    animation: animateBorderHorizontal1 4s linear infinite;
}

@keyframes animateBorderHorizontal1 {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.animation__borderRotate {
    transform: rotate(180deg);
}


/* setion SAMPLES */

.samples {
    padding: 4rem 1rem;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.slider {
    width: 100%;
    height: 100%;
}



/* media query */

@media (min-width: 500px) {}


@media (min-width:800px) {

    .realizations__item {
        flex-direction: row;
    }

    .realizations__itemTextContainer {
        margin: 0 2rem 2rem 0;
    }
}