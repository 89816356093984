.background {
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(220, 220, 220) 50%, rgb(255, 255, 255) 100%);
    color: black;
}

.privacyContainer {
    margin: 4rem auto 0 auto;
    max-width: 1200px;
}

.privacyContainer figure {
    display: flex;
    justify-content: center;
    max-width: 640px;
    margin: auto;
}

.privacyContainer img {
    height: 100%;
    width: 100%;
}

.privacyContainer h1 {
    font-size: 2rem;
    text-align: center;
    padding: 4rem 0 0 0;
}

.privacyContainer hr {
    margin: 4rem 0;
}

.privacyContainer h2 {
    font-size: 1.5rem;
    padding: 0 2rem;
    color: rgb(var(--accent));
}

.font {
    font-family: 'Kalam', cursive;
}

.privacyContainer ul {
    padding: 1rem;
    line-height: 1.5rem;
}

.privacyContainer ol {
    padding: 1rem 2rem;
    line-height: 1.5rem;
}

.privacyContainer li {
    font-size: 1rem;
    padding: 0.5rem;
}

.privacyContainer p {
    font-size: 1rem;
    line-height: 1.5rem;
}